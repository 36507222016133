const portugueseOptions = () => [
  {
    id: "portuguese-companyTaxId",
    message:
      "Por favor, forneça o Número de Identificação Fiscal da sua empresa (CNPJ).",
    trigger: "portuguese-companyTaxIdInput",
  },
  {
    id: "portuguese-companyTaxIdInput",
    user: true,
    validator: (value) => {
      if (/^\d{14}$/.test(value)) {
        return true;
      } else {
        return "Por favor, insira um CNPJ válido com 14 dígitos numéricos.";
      }
    },
    trigger: "portuguese-companyName",
  },
  {
    id: "portuguese-companyName",
    message: "Por favor, forneça a Razão Social da sua empresa.",
    trigger: "portuguese-companyNameInput",
  },
  {
    id: "portuguese-companyNameInput",
    user: true,
    validator: (value) => {
      if (/^[A-Za-z\s]+$/.test(value)) {
        return true;
      } else {
        return "Somente letras e espaços são permitidos!";
      }
    },
    trigger: "portuguese-fullName",
  },
  {
    id: "portuguese-fullName",
    message: "Por favor, forneça o seu Nome Completo.",
    trigger: "portuguese-fullNameInput",
  },
  {
    id: "portuguese-fullNameInput",
    user: true,
    validator: (value) => {
      if (/^[A-Za-z\s]+$/.test(value)) {
        return true;
      } else {
        return "Somente letras e espaços são permitidos!";
      }
    },
    trigger: "portuguese-email",
  },
  {
    id: "portuguese-email",
    message: "Por favor, forneça o seu e-mail de contato.",
    trigger: "portuguese-emailInput",
  },
  {
    id: "portuguese-emailInput",
    user: true,
    validator: (value) => {
      if (/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
        return true;
      } else {
        return "Por favor, insira um endereço de e-mail válido!";
      }
    },
    trigger: "portuguese-landingMenu",
  },
  {
    id: "portuguese-landingMenu",
    message: "Selecione uma das opções abaixo:",
    trigger: "portuguese-provider",
  },
  {
    id: "portuguese-provider",
    options: [
      {
        value: "e",
        label: "Como se tornar um Fornecedor",
        trigger: "portuguese-provider-link",
      },
      {
        value: "f",
        label: "Pagamento a Fornecedores",
        trigger: "portuguese-payProviderData",
      },
    ],
  },
  {
    id: "portuguese-provider-link",
    component: (
      <a href="https://proveedores.cmpc.com/pt-br/Convertirse-Proveedor-pt-BR">
        Tornar-se um Fornecedor
      </a>
    ),
    trigger: "portuguese-landingMenu",
  },
  {
    id: "portuguese-payProviderData",
    options: [
      {
        value: 1,
        label: "Confirme o pagamento dos seus documentos",
        trigger: "portuguese-payInfo1",
      },
      {
        value: 2,
        label: "Ative as notificações de pagamento bancário em seu e-mail",
        trigger: "portuguese-payInfo2",
      },
      {
        value: 3,
        label: "Como faturar para o grupo CMPC",
        trigger: "portuguese-payInfo3",
      },
      {
        value: 4,
        label:
          "Inscrição no programa APOYO CMPC para antecipar o pagamento de suas faturas",
        trigger: "portuguese-apoyoCMPC",
      },
    ],
  },
  {
    id: "portuguese-payInfo1",
    message:
      "Você deve enviar suas perguntas para nossos e-mails disponibilizados em cada país, indicando a identificação fiscal da empresa (CNPJ), Razão Social e número(s) da(s) fatura(s) em dívida.",
    trigger: "portuguese-countrys",
  },
  {
    id: "portuguese-payInfo2",
    message:
      "Você deve solicitar uma atualização do(s) seu(s) contato(s) de cobrança. Indique o número de identificação fiscal da sua empresa (CNPJ), razão social, nome completo e e-mail. Envie o pedido para o e-mail indicado em cada país link ponto 1.",
    trigger: "portuguese-countrys",
  },
  {
    id: "portuguese-payInfo3",
    message:
      "Convidamos você a revisar os requisitos técnicos para emissão de faturas para o grupo CMPC no seguinte link:",
    trigger: "portuguese-billingInstructions",
  },
  {
    id: "portuguese-billingInstructions",
    component: (
      <a href="https://example.com/billing-instructions">
        Instruções de Faturamento
      </a>
    ),
    trigger: "portuguese-landingMenu",
  },
  {
    id: "portuguese-apoyoCMPC",
    message:
      "Para se inscrever no programa APOYO CMPC e antecipar o pagamento de suas faturas, acesse o seguinte link:",
    trigger: "portuguese-apoyoCMPC2",
  },
  {
    id: "portuguese-apoyoCMPC2",
    component: <a href="https://cmpc.monkey.exchange/">Inscrição APOYO CMPC</a>,
    trigger: "portuguese-landingMenu",
  },
  portugueseCountrys(),

  {
    id: "portuguese-chile",
    message:
      "Por favor, envie as informações solicitadas para portalpagos@cmpc.cl",
    trigger: "portuguese-landingMenu",
  },
  {
    id: "portuguese-peru",
    message:
      "Por favor, envie as informações solicitadas para Forsac Peru: pagosforsacperu@cmpc.com",
    trigger: "portuguese-landingMenu",
  },
  {
    id: "portuguese-mexico",
    message:
      "Por favor, envie as informações solicitadas para Forsac Mexico: pagosforsacmexico@cmpc.com",
    trigger: "portuguese-landingMenu",
  },
  {
    id: "portuguese-argentina",
    message:
      "Por favor, envie as informações solicitadas para FABI Argentina: pagosfabi@cmpc.com ou Forestal Bosques del Plata: pagosbdp@cmpc.com",
    trigger: "portuguese-landingMenu",
  },
  {
    id: "portuguese-usa",
    message:
      "Por favor, envie as informações solicitadas para CMPC USA: paymentscmpcusa@cmpc.com",
    trigger: "portuguese-landingMenu",
  },
  {
    id: "portuguese-brazil",
    message:
      "Por favor, envie as informações solicitadas para CMPC Riograndense: pagoscelulosariograndense@cmpc.com",
    trigger: "portuguese-landingMenu",
  },
];

const portugueseCountrys = () => ({
  id: "portuguese-countrys",
  options: [
    {
      value: 1,
      label: "Chile",
      trigger: "portuguese-chile",
    },
    {
      value: 2,
      label: "Peru",
      trigger: "portuguese-peru",
    },
    {
      value: 3,
      label: "Mexico",
      trigger: "portuguese-mexico",
    },
    {
      value: 4,
      label: "Argentina",
      trigger: "portuguese-argentina",
    },
    {
      value: 5,
      label: "USA",
      trigger: "portuguese-usa",
    },
    {
      value: 6,
      label: "Brazil",
      trigger: "portuguese-brazil",
    },
  ],
});

const englishOptions = () => [
  {
    id: "english-init",
    message: "Hello and Welcome to CMPC Supplier Portal!",
    trigger: "english-q-info2",
  },
  {
    id: "english-q-info2",
    message:
      "Before we assist you, we need to know some information about you.",
    trigger: "english-q-companyName",
  },
  {
    id: "english-q-companyName",
    message: "Please provide the Company Name...",
    trigger: "english-companyName",
  },
  {
    id: "english-companyName",
    user: true,
    validator: (value) => {
      if (/^[A-Za-z\s]+$/.test(value)) {
        return true;
      } else {
        return "Only letters and spaces are allowed!";
      }
    },
    trigger: "english-q-companyEmail",
  },
  {
    id: "english-q-companyEmail",
    message: "Great! Now we need the Email(s) for contact.",
    trigger: "english-companyEmail",
  },
  {
    id: "english-companyEmail",
    user: true,
    validator: (value) => {
      if (/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
        return true;
      } else {
        return "Please enter a valid Email address!";
      }
    },
    trigger: "english-q-companyTaxId",
  },
  {
    id: "english-q-companyTaxId",
    message: "Please provide the Company's Tax ID.",
    trigger: "english-companyTaxId",
  },
  {
    id: "english-companyTaxId",
    user: true,
    validator: (value) => {
      if (/^(\d{1,2}\.\d{3}\.\d{3}-[0-9Kk])$|^(\d{7,8}-[0-9Kk])$/.test(value)) {
        return true;
      } else {
        return "Please enter a valid Tax ID!";
      }
    },
    trigger: "english-q-invoiceNumber",
  },
  {
    id: "english-q-invoiceNumber",
    message: "Finally, please provide the Invoice Number.",
    trigger: "english-invoiceNumber",
  },
  {
    id: "english-invoiceNumber",
    user: true,
    validator: (value) => {
      if (/^[A-Za-z0-9]+$/.test(value)) {
        return true;
      } else {
        return "Only letters and numbers are allowed!";
      }
    },
    trigger: "english-q-info3",
  },
  {
    id: "english-q-info3",
    message: "Great! Now please select the reason for your inquiry...",
    trigger: "english-landingMenu",
  },
  {
    id: "english-provider",
    message: "Please visit the following link for more information...",
    trigger: "english-provider-link",
  },
  {
    id: "english-provider-link",
    component: (
      <a href="https://proveedores.cmpc.com/en-us/Convertirse-Proveedor-en-US">
        Become a Supplier
      </a>
    ),
    trigger: "english-q-info3",
  },
  englishLandingMenu(),
  englishPayProviderData(),
  {
    id: "english-payInfo1",
    message:
      "You must send your inquiries to the emails provided by country, indicating the company’s Tax Id, company name, and invoice number ",
    trigger: "english-countrys",
  },
  {
    id: "english-payInfo2",
    message:
      "Please provide an email to receive payment remittance notifications. Action: You must provide an updated Accounts Receivables contact information, including an email. Tax Id, company name. Please send this information to the email provided in each country link. Point 1.",
    trigger: "english-countrys",
  },
  {
    id: "english-payInfo3",
    message:
      "You are invited to review the requirements and billing instructions for billing to CMPC Group.",
    trigger: "english-billingInstructions",
  },
  {
    id: "english-billingInstructions",
    component: (
      <a href="https://example.com/billing-instructions">
        Billing Instructions
      </a>
    ),
    trigger: "english-landingMenu",
  },
  {
    id: "english-apoyoCMPC",
    message:
      "To enroll in the APOYO CMCP program and receive payment information for your invoices, please register at the following link (Chile):",
    trigger: "english-apoyoCMPC2",
  },
  {
    id: "english-apoyoCMPC2",
    component: (
      <a href="https://apoyocmpc.monkeyexchange.cl/">APOYO CMPC Registration</a>
    ),
    trigger: "english-q-info3",
  },
  englishCountrys(),

  {
    id: "english-chile",
    message: "Please send the requested information to portalpagos@cmpc.cl",
    trigger: "english-landingMenu",
  },
  {
    id: "english-peru",
    message:
      "Please send the requested information to Forsac Peru: pagosforsacperu@cmpc.com",
    trigger: "english-landingMenu",
  },
  {
    id: "english-mexico",
    message:
      "Please send the requested information to Forsac Mexico: pagosforsacmexico@cmpc.com",
    trigger: "english-landingMenu",
  },
  {
    id: "english-argentina",
    message:
      "Please send the requested information to FABI Argentina: pagosfabi@cmpc.com or Forestal Bosques del Plata: pagosbdp@cmpc.com",
    trigger: "english-landingMenu",
  },
  {
    id: "english-usa",
    message:
      "Please send the requested information to CMPC USA: paymentscmpcusa@cmpc.com",
    trigger: "english-landingMenu",
  },
  {
    id: "english-brazil",
    message:
      "Please send the requested information to CMPC Riograndense: pagoscelulosariograndense@cmpc.com",
    trigger: "english-landingMenu",
  },
];

const englishLandingMenu = () => ({
  id: "english-landingMenu",
  options: [
    {
      value: 1,
      label: "How to become a Supplier",
      trigger: "english-provider",
    },
    {
      value: 2,
      label: "Supplier Payments",
      trigger: "english-payProviderData",
    },
  ],
});

const englishPayProviderData = () => ({
  id: "english-payProviderData",
  options: [
    {
      value: 1,
      label: "Confirm the payment amount of your invoices",
      trigger: "english-payInfo1",
    },
    {
      value: 2,
      label:
        "Please provide an email to receive payment remittance notifications",
      trigger: "english-payInfo2",
    },
    {
      value: 3,
      label: "Billing to CMPC Group",
      trigger: "english-payInfo3",
    },
    {
      value: 4,
      label:
        "Enroll in the APOYO CMPC program to receive payment information of your invoices (Chile)",
      trigger: "english-apoyoCMPC",
    },
  ],
});

const englishCountrys = () => ({
  id: "english-countrys",
  options: [
    {
      value: 1,
      label: "Chile",
      trigger: "english-chile",
    },
    {
      value: 2,
      label: "Peru",
      trigger: "english-peru",
    },
    {
      value: 3,
      label: "Mexico",
      trigger: "english-mexico",
    },
    {
      value: 4,
      label: "Argentina",
      trigger: "english-argentina",
    },
    {
      value: 5,
      label: "USA",
      trigger: "english-usa",
    },
    {
      value: 6,
      label: "Brazil",
      trigger: "english-brazil",
    },
  ],
});

const spanishOptions = () => [
  {
    id: "init-spanish",
    message: "¡Hola y Bienvenido al portal de Proveedores de CMPC!",
    trigger: "q-info2",
  },
  {
    id: "q-info2",
    message: "Antes de ayudarte, necesitamos saber algunas cosas sobre ti.",
    trigger: "q-companyName",
  },

  {
    id: "q-companyName",
    message: "Indicanos en que Empresa trabajas...",
    trigger: "companyName",
  },
  {
    id: "companyName",
    user: true,
    validator: (value) => {
      if (/^[A-Za-z]+$/.test(value)) {
        return true;
      } else {
        return "¡Solo se aceptan letras!";
      }
    },
    trigger: "q-companyId",
  },

  {
    id: "q-companyId",
    message: ({ previousValue }) => `Indicanos el RUT de ${previousValue}`,
    trigger: "companyId",
  },
  {
    id: "companyId",
    user: true,
    validator: (value) => {
      if (/^(\d{1,2}\.\d{3}\.\d{3}-[0-9Kk])$|^(\d{7,8}-[0-9Kk])$/.test(value)) {
        return true;
      } else {
        return "¡Debe ingresar un RUT válido!";
      }
    },
    trigger: "q-companyContactMail",
  },

  {
    id: "q-companyContactMail",
    message: "¡Excelente!, ahora necesitamos un Correo de Contacto",
    trigger: "companyContactMail",
  },
  {
    id: "companyContactMail",
    user: true,
    validator: (value) => {
      if (/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
        return true;
      } else {
        return "¡Debe ingresar un Correo válido!";
      }
    },
    trigger: "q-companyContactPhoneNumber",
  },

  {
    id: "q-companyContactPhoneNumber",
    message: "Por último necesitamos un Número Teléfonico de Contacto",
    trigger: "companyContactPhoneNumber",
  },
  {
    id: "companyContactPhoneNumber",
    user: true,
    validator: (value) => {
      if (/^(\+?56)?(\s?\d\s?){9,11}$/.test(value)) {
        return true;
      } else {
        return "¡Debe ingresar un número de teléfono Móvil o Fijo!";
      }
    },
    trigger: "q-info3",
  },

  {
    id: "q-info3",
    message: "Muy bien, ahora indicanos el motivo de tu consulta...",
    trigger: spanishLandingMenu().id,
  },

  {
    id: "spanish-provider",
    message: "Ingrese al siguiente Link para obtener Mayor Información...",
    trigger: "spanish-provider-link",
  },

  {
    id: "spanish-provider-link",
    component: (
      <a href="https://proveedores.cmpc.com/es-es/Convertirse-Proveedor">
        Convertirse en Proveedor
      </a>
    ),
    trigger: "q-info3",
  },

  spanishLandingMenu(),
  spanishPayProviderData(),

  {
    id: "payInfo1",
    message:
      "Debe enviar sus consultas a nuestros correos dispuestos en cada país, " +
      "indicando identificación fiscal de la empresa, Razón Social y número(s) de factura(s) adeudadas.",
    trigger: spanishCountrys().id,
  },

  {
    id: "payInfo2",
    message:
      "Debe enviar sus consultas a nuestros correos dispuestos en cada país " +
      "para actualizar contacto(s) de Cobranza. Incluya RUT, Razón Social " +
      "nombre completo y correo del usuario a notificar. Respuesta en máximo 2 días hábiles.",
    trigger: spanishCountrys().id,
  },

  spanishCountrys(),

  {
    id: "chile",
    message: "Envíe los datos solicitados a portalpagos@cmpc.cl",
    trigger: spanishLandingMenu().id,
  },
  {
    id: "peru",
    message:
      "Envíe los datos solicitados a Forsac Perú: pagosforsacperu@cmpc.com",
    trigger: spanishLandingMenu().id,
  },
  {
    id: "mexico",
    message:
      "Envíe los datos solicitados a Forsac México: pagosforsacmexico@cmpc.com",
    trigger: spanishLandingMenu().id,
  },
  {
    id: "argentina",
    message:
      "Envíe los datos solicitados a FABI Argentina: pagosfabi@cmpc.com ó Forestal Bosques del Plata : pagosbdp@cmpc.com ",
    trigger: spanishLandingMenu().id,
  },
  {
    id: "usa",
    message: "Envíe los datos solicitados a CMPC USA: paymentscmpcusa@cmpc.com",
    trigger: spanishLandingMenu().id,
  },
  {
    id: "brasil",
    message:
      "Envíe los datos solicitados a CMPC Riograndense: pagoscelulosariograndense@cmpc.com",
    trigger: spanishLandingMenu().id,
  },

  dteToCMPC(),

  {
    id: "dteSII1",
    message: "Si factura desde el SII, lo invitamos a ver el siguiente video:",
    trigger: "dteSII2",
  },

  {
    id: "dteSII2",
    component: (
      <a href="https://player.vimeo.com/video/809820204">Video Explicativo</a>
    ),
    trigger: spanishLandingMenu().id,
  },

  {
    id: "dteERP1",
    message:
      "Si factura desde un sistema(ERP) distinto a la plataforma del SII le invitamos a revisar video:",
    trigger: "dteERP2",
  },

  {
    id: "dteERP2",
    component: (
      <a href="https://player.vimeo.com/video/809820105">Video Explicativo</a>
    ),
    trigger: spanishLandingMenu().id,
  },

  {
    id: "apoyoCMPC",
    message: "Ingrese al siguiente Link para obtener Mayor Información...",
    trigger: "apoyoCMPC2",
  },

  {
    id: "apoyoCMPC2",
    component: <a href="https://apoyocmpc.monkeyexchange.cl/">Apoyo CMPC</a>,
    trigger: "q-info3",
  },
];

const dteToCMPC = () => ({
  id: "q-dteToCMPC",
  options: [
    {
      value: 5,
      label: "¿Cómo facturar desde el Portal SII?",
      trigger: "dteSII1",
    },
    {
      value: 6,
      label: "¿Cómo facturar desde mi Sistema o ERP?",
      trigger: "dteERP1",
    },
  ],
});

const spanishLandingMenu = () => ({
  id: "spanish-landingMenu",
  options: [
    {
      value: 1,
      label: "¿Cómo puedo ser un Proveedor?",
      trigger: "spanish-provider",
    },
    {
      value: 2,
      label: "Necesito información sobre los Pagos de Proveedores.",
      trigger: spanishPayProviderData().id,
    },
  ],
});

const spanishPayProviderData = () => ({
  id: "spanish-payProviderData",
  options: [
    {
      value: 5,
      label: "¿Cómo puedo confirmar el pago de mis Documentos?",
      trigger: "payInfo1",
    },
    {
      value: 6,
      label: "No he recibido notificaciones de Pagos Bancarios.",
      trigger: "payInfo2",
    },
    {
      value: 7,
      label: "¿Como puedo facturar al Grupo CMPC?",
      trigger: dteToCMPC().id,
    },
    {
      value: 14,
      label:
        "Inscripción en programa APOYO CMPC para anticipar el pago de sus facturas.(Chile)",
      trigger: "apoyoCMPC",
    },
  ],
});

const options = () => ({
  options: [
    {
      id: "q-intialMessage",
      message: "Seleccione un Idioma - Select a Language - Selecione um Idioma",
      trigger: languageMenu().id,
    },

    languageMenu(),

    {
      id: "spanish-options",
      message: "Has Seleccionado el Idioma Español",
      trigger: "init-spanish",
    },

    {
      id: "english-options",
      message: "English Language",
      trigger: "english-init",
    },

    {
      id: "portuguese-options",
      message: "Português",
      trigger: "portuguese-companyTaxId",
    },

    ...spanishOptions().map((item) => item),
    ...englishOptions().map((item) => item),
    ...portugueseOptions().map((item) => item),
  ],
});

const languageMenu = () => ({
  id: "languageMenu",
  options: [
    {
      value: "spanish",
      label: "Español",
      trigger: "spanish-options",
    },
    {
      value: "english",
      label: "English",
      trigger: "english-options",
    },
    {
      value: "portuguese",
      label: "Português",
      trigger: "portuguese-options",
    },
  ],
});

const spanishCountrys = () => ({
  id: "spanish-countrys",
  options: [
    {
      value: 8,
      label: "Chile",
      trigger: "chile",
    },
    {
      value: 9,
      label: "Perú",
      trigger: "peru",
    },
    {
      value: 10,
      label: "México",
      trigger: "mexico",
    },
    {
      value: 11,
      label: "Argentina",
      trigger: "argentina",
    },
    {
      value: 12,
      label: "USA",
      trigger: "usa",
    },
    {
      value: 13,
      label: "Brasil",
      trigger: "brasil",
    },
  ],
});

export default options;
