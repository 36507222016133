import React  from 'react'
import Chatbot from '../src/components/chatbot/Chatbot'   
import { BrowserRouter as Router, Route } from 'react-router-dom'; 
import   './App.css'  

const App = () => {
  return (
    <Router>  
      <div className="bot">
            <Route path="/" component={Chatbot} exact />
          </div>

    </Router>
  )
}

export default App
