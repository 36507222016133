import React, { useState, useEffect } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import "../../App.css";
import options from "./Options";

const theme = {
  background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#45963b",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#45963b",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const config = {
  width: "300px",
  height: "400px",
  hideUserAvatar: true,
  placeholder: "Escriba su respuesta.",
  headerTitle: "Asistente Virtual",
};

const Chatbot = (props) => {
  const [showChat, setShowChat] = useState(false);
  const [steps, setSteps] = useState([]);

  const handleChatPressed = () => {
    if (showChat) {
      setShowChat(false);
    }
    if (!showChat) {
      setShowChat(true);
    }
  };

  useEffect(() => {
    const array = options().options;
    setSteps(array);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          position: "absolute",
          bottom: 75,
          right: 20,
        }}
      >
        {showChat ? (
          <div>
            <ChatBot
              speechSynthesis={{ enable: false }}
              recognitionEnable={true}
              steps={steps}
              {...config}
            />
          </div>
        ) : null}
      </div>

      <div
        style={{
          position: "absolute",
          right: 16,
          bottom: 16,

          alingItems: "center",
          justifyContent: "center",

          backgroundColor: "#45963b",
          borderRadius: 100,
          cursor: "pointer",

          width: 50,
          height: 50,
          padding: 10,
        }}
        onClick={() => handleChatPressed()}
      >
        <img
          src={require("../img/initChatIcon.png")}
          style={{ width: "100%", height: "100%", alignSelf: "center" }}
        />
      </div>
    </ThemeProvider>
  );
};

export default Chatbot;

/* const [steps, setSteps] = useState([
  {
    id: "q-nombreEmpresa",
    message: "Hola ingresa nombre empresa..!!!",
    trigger: "nombreEmpresa",
  },
  {
    id: "nombreEmpresa",
    user: true,
    validator: (value) => {
      if (/^[A-Za-z]+$/.test(value)) {
        return true;
      } else {
        return "Ingrese solo caracteres alfabéticos.";
      }
    },
    trigger: "q-rut",
  },
  {
    id: "q-rut",
    message: "Ingresa el rut de empresa",
    trigger: "rut",
  },
  {
    id: "rut",
    user: true,
    validator: (value) => {
      if (/^[A-Za-z0-9\s]+$/.test(value)) {
        return true;
      } else {
        return "Ingrese solo caracteres alfabéticos.";
      }
    },
    trigger: "q-correo",
  },
  {
    id: "q-correo",
    message: "Ingresa un correo contacto",
    trigger: "correo",
  },
  {
    id: "correo",
    user: true,
    validator: (value) => {
      if (
        /^[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}/.test(
          value
        )
      ) {
        return true;
      } else {
        return "Formato correo incorrecto.";
      }
    },
    trigger: "q-telefono",
  },
  {
    id: "q-telefono",
    message: "Ingresa un telefono contacto",
    trigger: "telefono",
  },
  {
    id: "telefono",
    user: true,
    validator: (value) => {
      if (/^[0-9]+$/.test(value)) {
        return true;
      } else {
        return "Ingrese solo caracteres alfabéticos.";
      }
    },
    trigger: "rmcbot",
  },
  {
    id: "rmcbot",
    message: "Por ahora te puedo ofrecer !! ?",
    trigger: "qtype",
  },
  {
    id: "qtype",
    options: [
      { value: 1, label: "1. Como ser proveedor. ?", trigger: "3" },
      { value: 2, label: "2. Pago de Proveedores.", trigger: "4" },
      { value: 3, label: "3. Contacto con CMPC. ", trigger: "5" },
      { value: 4, label: "4. Mensaje al proveedor. ", trigger: "6" },
    ],
  },
  {
    id: "3",
    message:
      "Mostrar el link para dirigirse a sesión como convertirse en proveedor .",
    trigger: "submit",
  },
  {
    id: "4",
    options: [
      {
        value: 1,
        label: "1. Confirmar el pago de sus documentos.  ?",
        trigger: "3",
      },
      {
        value: 2,
        label:
          "2. Si Ud. no ha recibido notificaciones de pagos bancarias a su correo electrónico.",
        trigger: "55",
      },
      {
        value: 3,
        label:
          "3. Información para facturar al grupo CMPC desde la plataforma del S.I.I.",
        trigger: "33",
      },
      {
        value: 4,
        label:
          "4. Información para facturar al grupo CMPC desde un sistema(ERP) distinto a la plataforma del S.I.I.  ",
        trigger: "6",
      },
    ],
    trigger: "qtype",
  },
  {
    id: "55",
    message:
      "Acción: Debe enviar correo a portalpagos@cmpc.cl  e indicar RUT de empresa, Razón Social, Confirmar sociedad de CMPC a la que fue emitida su factura e indicar número(s) de factura(s) adeudadas y monto. Su requerimiento será respondido en el plazo máximo de 2 días hábiles.",
    trigger: "qtype",
  },
  {
    id: "33",
    message:
      "Acción: Debe asegurar la correcta emisión de su documento tributario indicando obligatoriamente los siguientes datos según tipo de compra" +
      "•Para compra de materiales. Se debe indicar Número de orden de compra (OC) y Número de Guía(s) de despacho" +
      "•Para contratación de servicios.  Se debe indicar Número de orden de compra (OC) , Número de Hoja de Aceptación del Servicios (HAS) o (HES), previamente autorizada." +
      "•En el caso de servicios de flete. Se debe indicar Número de orden de compra (OC, pedido flete ) y Numero de Proforma o Folio ." +
      "•Las facturas referenciadas con estos números permiten una correcta recepción y registro contable para generar su posterior pago." +
      "•Si no posee la información previa a la emisión de la factura, debe contactar al área de compras respectiva y solicitar dicha información.",
    trigger: "qtype",
  },
  {
    id: "5",
    message:
      "An election is a way people can choose their candidate or their preferences in a representative democracy or other form of government",
    trigger: "qtype",
  },
  {
    id: "6",
    component: <Link />,
    trigger: "q-submit",
  },
  {
    id: "q-submit",
    message: "Tiene alguna otra pregunta !?",
    trigger: "submit",
  },
  {
    id: "submit",
    options: [
      { value: "y", label: "Yes", trigger: "no-submit" },
      { value: "n", label: "No", trigger: "end-message" },
    ],
  },
  {
    id: "no-submit",
    options: [
      { value: 1, label: "Impuesto a la propiedad ?", trigger: "4" },
      { value: 2, label: "Professional Tax ?", trigger: "3" },
      { value: 3, label: "Election Department", trigger: "5" },
      { value: 4, label: "More Information", trigger: "6" },
    ],
  },
  {
    id: "end-message",
    component: <Post />,
    asMessage: true,
    end: true,
  },
]); */
